import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import JobSkeleton from './JobSkeleton';
import '../styles/AccountUpgrade.css';

const AccountUpgrade = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://ampcds.onrender.com/api/auth/subscribed-users');
        localStorage.setItem('unsub-users', JSON.stringify(response.data.users));
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users);
    } else {
      const fuse = new Fuse(users, {
        keys: ['fullName', 'phoneNumber', 'businessName', 'privateName'],
        includeScore: true,
        threshold: 0.4,
      });
      const result = fuse.search(searchTerm);
      setFilteredUsers(result.map(item => item.item));
    }
  }, [searchTerm, users]);

  const handleUpgrade = async (userId) => {
    try {
      await axios.post('https://ampcds.onrender.com/api/auth/update-subscription', { userId });
      alert('User subscription updated successfully!');
    } catch (error) {
      console.error('Error updating subscription:', error);
      alert('Failed to update user subscription. Please try again.');
    }
  };

  return (
    <div className="upgrade-container">
      <header className="upgrade-header">
        <Link to="/" className="back-link">&#8592; Back to Home</Link>
        <h2>Upgrade User Account</h2>
      </header>

      <div className="upgrade-search">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className="user-count">Total Users: {filteredUsers.length}</span>
      </div>

      {loading ? (
        <JobSkeleton />
      ) : (
        <div className="user-list">
          {filteredUsers.map(user => (
            <div key={user._id} className="user-card">
              <div className="user-info">
                <p><strong>Full Name:</strong> {user.fullName || 'N/A'}</p>
                <p><strong>Phone:</strong> {user.phoneNumber || 'N/A'}</p>
                <p><strong>Business:</strong> {user.businessName || 'N/A'}</p>
                <p><strong>Private Name:</strong> {user.privateName || 'N/A'}</p>
                <p><strong>Runor Units:</strong> {user.runorUnits != null ? user.runorUnits : 'N/A'}</p>
                <p><strong>Agent:</strong> {user.isAgent ? 'Yes' : 'No'}</p>
                <p><strong>Verification Code:</strong> {user.verificationCode || 'N/A'}</p>
                <p><strong>Account No:</strong> {user.accountNo != null ? user.accountNo : 'N/A'}</p>
                <p><strong>Account Name:</strong> {user.accountName || 'N/A'}</p>
                <p><strong>Bank:</strong> {user.bankName || 'N/A'}</p>
                <p><strong>Verified:</strong> {user.isVerified ? 'Yes' : 'No'}</p>
              </div>
              <button className="upgrade-btn" onClick={() => handleUpgrade(user._id)}>
                Upgrade
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccountUpgrade;
