import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PhoneNumberVerification = () => {
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(45);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
    setPin(value.slice(0, 4)); // Limit to 4 digits
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const user = JSON.parse(localStorage.getItem('user')); // Retrieve user from local storage
      if (!user || !user._id) {
        setErrorMessage('User not found. Please log in again.');
        setLoading(false);
        return;
      }

      const response = await axios.post('https://ampcds.onrender.com/api/auth/verify', {
        userId: user._id,
        verificationCode: pin,
      });

      console.log('Backend response:', response.data);

      if (response.status === 200) {
        navigate('/');
      } else {
        setErrorMessage(response.data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Verification error:', error.response?.data || error.message);
      setErrorMessage(error.response?.data?.error || 'Verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResend = () => {
    if (resendTimer === 0) {
      setResendTimer(45);
      // Trigger resend OTP API call
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto', textAlign: 'center' }}>
      <h2>Phone Number Verification</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <input
          type="text"
          value={pin}
          onChange={handleChange}
          maxLength="4"
          placeholder="Enter 4-digit code"
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        />
        {errorMessage && <p style={{ color: 'red', fontSize: '14px' }}>{errorMessage}</p>}
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: '10px',
            fontSize: '16px',
            backgroundColor: loading ? '#ccc' : '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading ? 'Verifying...' : 'Verify'}
        </button>
      </form>
      <div style={{ marginTop: '15px' }}>
        <button
          onClick={handleResend}
          disabled={resendTimer > 0}
          style={{
            padding: '8px',
            fontSize: '14px',
            backgroundColor: resendTimer > 0 ? '#ccc' : '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: resendTimer > 0 ? 'not-allowed' : 'pointer',
          }}
        >
          Resend OTP
        </button>
        {resendTimer > 0 && <p style={{ fontSize: '14px', marginTop: '5px' }}>Resend in {resendTimer}s</p>}
      </div>
    </div>
  );
};

export default PhoneNumberVerification;
