import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/AgentPromo.css';

const AgentPromo = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const data = {
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      phoneNumber: formData.get('phoneNumber'),
      houseAddress: formData.get('houseAddress'),
      hasAccount: formData.get('hasAccount'),
      operationBase: formData.get('operationBase') === 'others' ? formData.get('otherBase') : formData.get('operationBase'),
      agentLocation: formData.get('agentLocation'),
    };

    try {
      const response = await axios.post('https://ampcds.onrender.com/api/agent-promo', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Response:', response.data);
      alert('Form submitted successfully!');

      e.target.reset(); // Reset the form after successful submission

      if (data.hasAccount === 'no') {
        navigate('/signup');
      } else if (data.hasAccount === 'yes') {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="agent-promo-container">
      <div className="agent-promo-content">
        <h1 className="promo-title">"Your Moment to Shine Has Arrived"</h1>
        <p className="promo-message">
          Imagine a platform where your potential meets endless opportunities. Welcome to <strong>irunor</strong>,
          the ultimate space for connecting dreams with reality. Whether you’re looking to start your entrepreneurial
          journey, expand your influence, or simply explore new horizons, we’ve got you covered.
        </p>
        <p className="promo-message">
          Here’s the thing: Nigeria is a land of vibrant possibilities, and with <strong>irunor</strong>, you’re not just
          finding jobs or employees—you’re creating a legacy. Picture this: as an irunor agent, you’ll connect
          businesses to the workforce they need, provide solutions for everyday needs, and be the bridge that transforms lives.
        </p>
        <p className="promo-message">
          Why join <strong>irunor</strong>?
        </p>
        <ul className="promo-benefits">
          <li>Build your own business with zero upfront cost.</li>
          <li>Access a wide network of professionals and service providers.</li>
          <li>Earn commissions while making a tangible difference in people’s lives.</li>
          <li>Be part of a community that celebrates and supports your growth.</li>
        </ul>
        <p className="promo-message">
          The journey to greatness doesn’t have to be rushed. Take it slow, deliberate, and confident—let the
          world see what you’re truly capable of. This is more than a job; it’s a calling to lead, inspire, and impact.
        </p>
        <p className="promo-message">
          Step into the spotlight. Your future, your success, and your legacy are waiting.
        </p>
      </div>

      <div className="interested-users-form">
        <h2 className="form-title">Take advantage of this opportunity by filling out this form and we will be in contact soon.</h2>
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" required />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" required />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input type="tel" id="phoneNumber" name="phoneNumber" required />
          </div>
          <div className="form-group">
            <label htmlFor="houseAddress">House Address</label>
            <input type="text" id="houseAddress" name="houseAddress" required />
          </div>
          <div className="form-group">
            <label htmlFor="hasAccount">Do you have an account on irunor?</label>
            <select id="hasAccount" name="hasAccount" required>
              <option value="">Choose an option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="operationBase">Where will you be running this from if you’re approved?</label>
            <select
              id="operationBase"
              name="operationBase"
              required
              onChange={(e) => {
                if (e.target.value === 'others') {
                  document.getElementById('otherBase').style.display = 'block';
                } else {
                  document.getElementById('otherBase').style.display = 'none';
                }
              }}
            >
              <option value="">Choose an option</option>
              <option value="store">My Store (Free Kit)</option>
              <option value="house">My House (Free Kit)</option>
              <option value="others">Others</option>
            </select>
            <input type="text" id="otherBase" name="otherBase" placeholder="Please specify" style={{ display: 'none' }} />
          </div>
          <div className="form-group">
  <label htmlFor="agentLocation">Location of Agent</label>
  <input type="text" id="agentLocation" name="agentLocation" required placeholder="Enter your location" />
</div>
          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AgentPromo;
