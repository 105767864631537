import React from 'react';
import AccountUpgrade from '../components/AccountUpgrade'; // Adjust path if needed

const CustomerOverviewPage = () => {
  return (
    <div>
      <AccountUpgrade />
    </div>
  );
};

export default CustomerOverviewPage;
